<template>
	<div>
		<H5header :type="2"></H5header>
		<img :src="image" alt="" class="backimg">
		<div>
			<div class="xqxx">
				<div class="title">{{eventdate.matchName}}</div>
			</div>
			<div>
				<p class="head-name">ꔷ 报名时间：{{basicdate.registerDateStart}}</p>
				<p class="head-name" style="margin-left: 105PX">至</p>
				<p class="head-name" style="margin-left: 105PX">{{basicdate.registerDateEnd}}</p>
			</div>
			<div class="head-name" style="display: flex">
				<div class="name">ꔷ 比赛时间：</div>
				<div>
					<div v-for="(item,index) in eventdate.matchDate" :key="index">
						<div v-if="item.startDate == item.endDate">{{item.startDate}}</div>
						<div v-else>{{item.startDate}} 至 {{item.endDate}}</div>
					</div>
				</div>
			</div>
			<div class="head-name">ꔷ 赛事地点：{{eventdate.matchAddressStadium}}</div>
		</div>
		<div class="xian"></div>

		<div class="event">
			<div :class="triggerindex == 1 ? 'eventbut eventbut2':'eventbut'" @click="gettrigger(1)">
				竞赛规程
			</div>
			<div :class="triggerindex == 2 ? 'eventbut eventbut2':'eventbut'" @click="gettrigger(2)">
				组别项目
			</div>
			<div :class="triggerindex == 3 ? 'eventbut eventbut2':'eventbut'" @click="gettrigger(3)">
				赛事报名
			</div>
		</div>
		<div class="xian"></div>
		<div class="juti">
			<div v-html="date" v-if="triggerindex == 1"></div>
			<div v-if="triggerindex == 2">
				<div class="nian">
					<div style="display: flex;align-items: center;">
						<img src="../../assets/image/runproject.png" alt="">
						<p>组别</p>
					</div>
					<div class="select">
						<!-- <el-select v-model="groupName" placeholder="请选择" clearable>
							<el-option v-for="item in options1" :key="item.groupName" :label="item.groupName"
								:value="item.groupName">
							</el-option>
						</el-select> -->
						<a-select option-label-prop="label"  placeholder="请选择" v-model="groupName" allowClear>
							<a-select-option v-for="item in options1" :key="item.groupName" :value="item.groupName" :label="item.groupName">
								<a-icon type="check" v-if="item===groupName"/> 
								{{item.groupName}}
							</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="xian2"></div>
				<div class="but" @click="gradeGrouping">
					搜索
				</div>

				<div class="table">
					<el-table :data="tableData" border style="width: 100%;"
						:header-cell-style="{ background: '#0A6DF3' }">
						<el-table-column type="index" label="序号" align="center" width="78">
						</el-table-column>
						<el-table-column prop="groupName" label="组别" align="center">
						</el-table-column>
						<el-table-column prop="projectName" label="项目" align="center">
						</el-table-column>
						<el-table-column prop="sex" label="性别" align="center">
						</el-table-column>
						<el-table-column prop="birthDate" label="出生日期" align="center">
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div v-if="triggerindex == 3">
				<div v-if="this.$store.state.userType != 'user_type_02'">
					<div class="contant-text">
						<p class="chose-title">团体用户未登录</p>
						<p class="type-name">请首先
							<a @click="applyfor">[登录团体用户]</a>，或首先
							<a href="/groupRegistration">[注册团体用户]</a>。
						</p>
					</div>
				</div>
				<div
					v-else-if="this.$store.state.userType == 'user_type_02' && isCurrentTimeGreaterThanTarget(basicdate.registerDateEnd)">
					<div class="contant-text" style="margin-top: 30PX;">
						<!-- <p class="chose-title">团体用户未登录</p> -->
						<p class="type-name">
							该赛事报名已经结束
						</p>
					</div>
				</div>
				<div
					v-else-if="this.$store.state.userType == 'user_type_02' && !isCurrentTimeGreaterThanTarget(basicdate.registerDateStart)">
					<div class="contant-text" style="margin-top: 30PX;">
						<!-- <p class="chose-title">团体用户未登录</p> -->
						<p class="type-name">报名未开始，报名时间时间是{{basicdate.registerDateStart}}，
							<a @click="$router.back(0)">[返回报名首页]</a>。
						</p>
					</div>
				</div>
				<div v-else>
					<div class="contant-text" style="margin-top: 30PX;">
						<!-- <p class="chose-title">团体用户未登录</p> -->
						<p class="type-name">
							<a @click="determine" target="_blank">请您进入团体用户中心，完成本赛事的报名操作</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	import H5header from "./components/header2.vue"
	export default {
		name: '',
		components: {
			H5header
		},
		data() {
			return {
				id: '',
				date: '',
				triggerindex: 1,
				title: '',
				title1: '',
				title2: '',
				title3: '',
				title4: '',
				imgs: '',
				total: 0,
				columnId: '',
				columnPid: '',
				tableData: [],
				options1: [],
				groupName: undefined,
				basicdate: {},
				eventdate: {},
				image: '',
				domain: 'https://t-jsglzx.bgosp.com',
				domain2: 'https://admin.bjcac.org.cn'
			}
		},
		created() {
			this.id = this.$route.query.id
			this.columnId = this.$route.query.columnId
			this.columnPid = this.$route.query.columnPid
			this.getBasicEnrollInfoByMatchId()
			this.getMatchInfoByMatchId()
			this.eventIntroduction()
		},
		methods: {
			getBasicEnrollInfoByMatchId() {
				this.$api.getBasicEnrollInfoByMatchId(this.$route.query.id).then(res => {
					this.basicdate = res.data.data.result
				})
			},
			eventIntroduction() {
				this.$api.eventIntroduction(this.$route.query.id).then(res => {
					res.data.data.result.matchAddressStadium = res.data.data.result.addresses.map(item => {
						return item.matchAddressStadium
					}).toString()
					if (res.data.data.result.matchImgs && res.data.data.result.matchImgs.length > 0) {
						this.$api.getFile({
							objectName: res.data.data.result.matchImgs[0].filePath
						}).then(res => {
							this.$convert(res.data.data.result.url).then((fileUrl) => {
								this.image = fileUrl
								this.$forceUpdate()
							})
						})
					}
					if (res.data.data.result.matchRules) {
						res.data.data.result.matchRules = this.updatedHtml(res.data.data.result.matchRules)
						let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi); //定义正则，筛选出img元素
						let matchres = res.data.data.result.matchRules.match(imgReg);
						if (matchres) {
							matchres.forEach((item) => {
								if (item.indexOf('staticMinioFileCheckSecurity') == -1) {
									return // 说明是历史数据需要返回原来的 不需要改变
								}
								// 从富文本中拿到img标签 并去掉 "/>"
								let _tempStr = item.slice(0, item.length - 2);
								let _index = _tempStr.indexOf('?');
								// 拿到文件的所有旧文件标签 [ img, img, img]
								const srcRegExp = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
								const _InitialImgSrc = _tempStr.match(srcRegExp);
								// 拿到 minFileName 获取新的src
								if (_InitialImgSrc && _InitialImgSrc.length > 0) {
									// 拿到文件的minFileName  事例： jeditor/90490405b7384040bfdd1d6e7bc14a79_1688977979650OSS.png
									let _fileNameIndex = _InitialImgSrc[0].indexOf('jeditor/');
									let _minFileName = _InitialImgSrc[0].substring(_fileNameIndex,
										_InitialImgSrc[0].length);
									// 拿到文件的minFileName + 后续属性 事例： jeditor/31580d55a8cc489c8d8aa92f59b310c0_1688977994678OSS.png" alt="" width="48" height="48"
									let longHtml = _tempStr.substring(_tempStr.indexOf(_minFileName),
										_tempStr
										.length);
									// 拿到属性 事例： alt="" width="100" height="100"
									let propName = longHtml.substring(longHtml.indexOf(' '), longHtml
										.length);
									// 获取新的验签
									const params = this.$getSign(_minFileName);
									// 拼接img标签
									let _str = _tempStr.substring(0, _index) + '?' + params + '"' +
										propName +
										'/>';
									// 替换img标签
									res.data.data.result.matchRules = res.data.data.result
										.matchRules.replace(item, _str);
								}
							});
						}
						this.date = res.data.data.result.matchRules
					}
					res.data.data.result.matchDate = JSON.parse(res.data.data.result.matchDate)

					this.eventdate = res.data.data.result
					document.title = res.data.data.result.matchName + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()

					let img =
						'https://admin.bjcac.org.cn/jeecgboot/jeecg-system/sys/common/staticMinioFile?minFileName=' +
						res.data.data.result.matchImgs[0].filePath
					this.$getwxsdk(res.data.data.result.matchName + '-北京市体育竞赛管理和国际交流中心', img)
				})
			},
			getMatchInfoByMatchId() {
				let data = {
					matchId: this.id,
				}
				this.$api.getMatchInfoByMatchId(data).then(res => {
					let options1 = []
					let tableData = []
					for (let i in res.data.data.result.matchSettings) {
						res.data.data.result.matchSettings[i].projects.forEach(item => {
							item.groupName = res.data.data.result.matchSettings[i].groupName
							item.birthDate = res.data.data.result.matchSettings[i].birthDate
							item.sex = res.data.data.result.matchSettings[i].sex == 1 ? '男' : (res.data
								.data.result.matchSettings[i].sex == 2 ? '女' : '混合')
						})
						options1.push(res.data.data.result.matchSettings[i])
						tableData.push(res.data.data.result.matchSettings[i].projects)
					}
					this.options1 = options1
					this.tableData = tableData.flat()
				})
			},
			updatedHtml(url) {
				const imgSrcRegex = /<img[^>]+src="([^"]+)"/g;
				let updatedHtml = url;
				let match;
				while ((match = imgSrcRegex.exec(url)) !== null) {
					const imgSrc = match[1];
					if (!/^https?:\/\//i.test(imgSrc)) {
						if (process.env.VUE_APP_BASE_API == 'https://www.bjcac.org.cn') {
							const updatedSrc = `${this.domain2.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
							updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
						} else {
							const updatedSrc = `${this.domain.replace(/\/$/, '')}/${this.removeDotDotSlash(imgSrc)}`;
							updatedHtml = updatedHtml.replace(imgSrc, updatedSrc);
						}
					}
				}
				return updatedHtml;
			},
			removeDotDotSlash(url) {
				let updatedUrl = url;
				while (updatedUrl.indexOf('../') !== -1) {
					updatedUrl = updatedUrl.replace('../', '');
				}
				return updatedUrl;
			},
			gettrigger(e) {
				this.triggerindex = e
				if (e == 1 || e == 2) {
					this.getMatchInfoByMatchId()
				}
				this.groupName = undefined
			},
			gradeGrouping() {
				for (let i in this.options1) {
					if (this.options1[i].groupName == this.groupName) {
						this.tableData = this.options1[i].projects
					}
				}
			},
			determine() {
				this.$api.getTimeStamp().then(ess => {
					let data = {
						accessToken: this.$store.state.token,
						redirectURL: '/match/enrollApply?matchName=' + this.eventdate.matchName,
						timestampe: ess.data.data.timestamp
					}
					this.$api.getCode(data).then(err => {
						let url = process.env.VUE_APP_BASE_API_ADMIN + '/tokenLogin?code=' + err.data.data
							.result.code
						window.open(url, '_self');
					})
				})
			},
			applyfor() {
				this.$store.commit("EXIT_TOKEN")
				this.$router.push({
					path: '/h5/login',
					query: {
						person: false,
						grp: true,
						refresh: new Date().getTime()
					}
				})
			},
			isCurrentTimeGreaterThanTarget(targetTime) {
				// 获取当前时间的时间戳（单位：毫秒）
				const currentTimeStamp = new Date().getTime();
				// 获取目标时间的时间戳
				const targetTimeStamp = new Date(targetTime).getTime();
				// 比较当前时间和目标时间
				console.log(targetTime, this.eventdate)
				if (currentTimeStamp > targetTimeStamp) {
					return true;
				} else if (currentTimeStamp < targetTimeStamp) {
					return false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.backimg {
		width: 100%;
		height: 220PX;
	}

	.xqxx {
		.title {
			font-size: 15PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			padding: 16PX 31PX;
			border-bottom: 1PX solid rgba(197, 197, 197, 0.2);
		}
	}

	.head-name {
		margin-top: 8PX;
		margin-left: 31PX;
		font-size: 13PX;
		font-weight: bold;
	}

	.xian {
		margin-top: 11PX;
		width: 100%;
		height: 4PX;
		background: #D8DDE6;
		opacity: 0.28;
	}

	.event {
		margin: 11PX 28PX 0;
		display: flex;
		flex-wrap: wrap;

		.eventbut {
			padding: 4PX 10PX;
			background: #EFF6FF;
			border-radius: 5PX;
			margin-right: 21PX;
			margin-bottom: 11PX;
		}

		.eventbut2 {
			border: 1PX solid rgba(30, 113, 239, 0.5);
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #1E71EF;
		}
	}

	.juti {
		margin: 11PX 28PX;
	}

	.nian {
		display: flex;
		align-items: center;
		justify-content: space-between;

		img {
			width: 17PX;
			height: 17PX;
			flex-shrink: 0;
		}

		p {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin-left: 12PX;
			flex-shrink: 0;
		}

		.select ::v-deep .el-select .el-input .el-input__inner {
			border-color: transparent !important;
			background-color: transparent !important;
			box-shadow: none !important;
			color: #000;
		}

		.select ::v-deep .el-select .el-input,
		.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}

		.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
			transform: rotateZ();
		}

		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #C0C4CC;
			font-size: 14PX;
			transition: transform .3s;
			transform: rotateZ(89deg);
			cursor: pointer;
			left: 15PX;
			position: relative;
		}

		.select ::v-deep input::-webkit-input-placeholder {
			color: #000;
		}

		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #000;
		}

		::v-deep .el-input .el-input__inner {
			border: none !important;
			padding: 0 12PX;
		}

		::v-deep input::-webkit-input-placeholder {
			color: #000;
		}
	}

	.xian2 {

		height: 1PX;
		background: rgba(194, 194, 194, 0.2);
	}

	.but {
		margin: 20PX 0;
		width: 100%;
		height: 37PX;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
		border-radius: 50PX;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
	}

	.table {
		margin-bottom: 40PX;
	}

	.table ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
		font-size: 11PX;
	}

	.table ::v-deep .el-table__header-wrapper {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table {
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}

	.table ::v-deep .el-table th.el-table__cell.is-leaf {
		border-bottom: 1PX solid #000;
		border-right: 1PX solid #000;
	}

	.table ::v-deep .el-table td,
	.el-table th.is-leaf,
	.el-table--border,
	.el-table--group {
		border-color: black;
	}

	.table ::v-deep .el-table--border::after,
	.el-table--group::after,
	.el-table::before {
		background-color: black;
	}

	.contant-text {
		padding: 20PX 0;
	}

	.contant-text .chose-title {
		text-align: center;
		font-size: 15PX;
		line-height: 3em;
		color: #666;
	}

	.contant-text .type-name {
		text-align: center;
		font-size: 13PX;
		line-height: 2em;
		color: #333;
	}
</style>